import http from '@/utils/http';


export const searchCountry = (parameter) => {
  return http.request({
    url: '/v1/games/country/search',
    method: 'post',
    data: parameter
  })
}


export const getCountrys = (parameter) => {
  return http.request({
    url: '/v1/games/country/list',
    method: 'post',
    params: parameter
  })
}

export const editCountry = (parameter) => {
  return http.request({
    url: '/v1/games/country/edit',
    method: 'post',
    data: parameter
  })
}