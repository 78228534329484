<template>
    <div>
        <countryList :game_id="game_id"></countryList>
    </div>
</template>

<script>

import countryList from '@/components/games/country/list.vue';

export default {
    components: {
        countryList
    },
    data () {
        let self = (window.x = this)
        return {
            game_id: 1,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>