<template>
    <Table 
        ref="table" 
        :height="tableHeight"
        :columns="columns" 
        :data="data" 
        :loading="loading" 
        @on-row-click="onRowClick"
    >
    </Table>
</template>

<script>

export default {
    name: "fit-table",
    props: {
        columns     : Array,    // 
        data        : Array,
        loading     : Boolean,
        header_height: Number,
    },
    components: {
    },
    data () {
        let self = (window.x = this);
        return {
            tableHeight: 100,  // 表格高度
        }
    },
    methods: {
        getTableHeight(){
            this.tableHeight = window.innerHeight - (this.header_height || 160);
        },
        // end
        onRowClick(row){
            this.$emit('on-row-click', row);
        }
    },
    mounted () {
        window.addEventListener('resize', this.getTableHeight);
	    this.getTableHeight();
    },
    watch: {
        
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "search.css" */
</style>